//AVAILABLE EVERYWHERE
// IMPORTS
@import (once) "/node_modules/basiclightbox/dist/basicLightbox.min.css";
@import (reference) "../settings.less";

.fancybox__container,
.basicLightbox {
    z-index: 100000;
}

.fancybox__container .has-iframe .fancybox__content {
    padding: 0;
    height: 90% !important;
}

.basicLightbox {
    background-color: rgba(0,0,0,.5);
}

// COMPARISON DRAWER
.comparison-drawer {
    position: fixed;
    bottom: 12px;
    right: 12px;
    background-color: white;
    z-index: 1000;
    box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.14);
    padding: 12px;
    width: auto !important;
    .grid();
    grid-template-columns: minmax(0,1fr) auto;
    gap: 18px 24px;
    border: 1px solid @main-color;

    > b {
        text-transform: uppercase;
        letter-spacing: 0.04em;
        font-size: 14px;
        line-height: 1.2;
        display: flex;
        align-items: center;
        line-height: 21px;
        font-size: 14px;
        text-transform: uppercase;
        grid-column: span 2;
        margin-right: 32px;

        &:after {
            content: ":";
        }
    }

    .remove-all {
        position: absolute;
        top: 16px;
        right: 16px;

        &:before {
            .small-icon();
            content: "\e803";
            font-size: 12px;
        }
    }

    .products-wrapper {
        display: flex;
        gap: 8px;

        .single-product {
            width: 100px;
            aspect-ratio: 6/4;
            position: relative;
            font-size: 12px;

            img {
                .img-contain();
            }

            i {
                position: absolute;
                top: 0;
                right: 0;
                padding: 5px;
                z-index: 1;
                cursor: pointer;
                border-radius: 2px;
                background: @light-bg;

                &:hover {
                    background-color: darken(@light-bg, 5%);
                }

                &:after {
                    .small-icon();
                    content: "\e803";
                    font-size: 9px;
                }
            }
        }
    }

    .confirm-button {
        gap: 10px;
        align-self: end;

        &:not(.has-two-products) {
            pointer-events: none;
            background-color: @footer-gray;
        }

        &:before {
            .small-icon();
            font-size: 20px;
            content: "\e917";
        }

        height: initial;
    }
}

// AMOUNT INPUT
.amount-input-wrapper {

    input[type="text"] {
        display: block;
        border: 1px solid @border-color;
        width: 100%;
        font-size: 16px;
        color: @header-color;
        text-align: center;
        border-radius: var(--borderRadius);
    }

    button {
        position: absolute;
        top: 0;
        width: 33%;
        height: 100%;
        cursor: pointer;
        touch-action: manipulation;
        z-index: 1;
        background-color: transparent;
        border-radius: var(--borderRadius);

        &:before, // -
        &:after { // |
            position: absolute;
            display: block;
            border-radius: 50%;
            background: @header-color;
            content: '';
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
        }

        &.minus {
            left: 0;

            &:after {
                display: none;
            }
        }

        &.plus {
            right: 0;
        }

        &:hover {

            &:before,
            &:after {
                background-color: @main-color !important;
            }
        }
    }
}

// INFOBOX
.infobox {
    display: flex;
    padding: 30px;
    padding-top: 10px;
    flex-direction: column;
    align-items: center;
    gap: 15px;
    border: 1px solid @border-color;
    text-align: center;

    &.infobox-success:before {
        content: '\e904';
        color: @success-color;
    }

    &.infobox-error:before {
        content: '\e903';
        color: @error-color;
    }

    > * {
        width: 60%;
    }

    &:before {
        content: '\e900';
        font-family: icomoon;
        font-size: 130px;
        color: @main-color;
    }

    .infobox-headline {
        font-size: 20px;
    }

    .infobox-message {

        a {
            text-decoration: underline;

            &:hover {
                color: @header-color;
            }
        }

        strong {
            color: @header-color;
        }
    }

    .confirm-button {
        margin-top: 10px;
        width: fit-content;
        height: 38px;
    }

    ol {
        padding-left: 0;
    }

    ul {

        li {
            justify-content: center;
        }
    }

    .confirm-button {
        margin: 0 auto;
    }
}

.new-password-form {
    width: 50%;
    margin-top: 20px;

    .form-hint {
        text-align: left;
    }
}

.listing-wrapper .infobox {
    width: 100%;
}

.tippy-box {
    background-color: white;
    color: @font-color;
    box-shadow: 0 1px 4px rgba(0,0,0,.55);
    border-radius: 0;

    .tippy-content {
        padding: 10px;
    }

    .tippy-arrow {
        color: white;

        &:before {
            filter: drop-shadow(0 2px 1px rgba(0,0,0,.32));
        }
    }
}

.tooltip-content {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 5px;

    > span {
        color: @header-color;
        font-weight: bold;
        font-size: 14px;
    }

    > p {
        font-size: 13px;
        margin: 0;
    }

    > a {
        color: @sec-color;
        border-radius: 5px;
        width: fit-content;
        text-decoration: underline;
        font-weight: bold;

        &:hover {
            opacity: .75;
        }
    }
}

[data-tippy-root] {
    z-index: 5 !important;
}

// MODAL STYLES
.goshop-modal {
    background-color: white;
    .shadow();
    max-height: 90vh;
    max-width: 90vw;
    overflow-y: auto;
    position: relative;
    width: 700px;
    border-radius: var(--borderRadius);

    label {
        user-select: none;
        cursor: pointer;
        margin-bottom: 25px;
        display: block;
        padding-left: 30px;
        position: relative;

        input[type="checkbox"] {
            display: none;

            &:checked + span:before {
                content: '\e816';
            }
        }

        span {
            font-size: 18px;
            position: absolute;
            top: 0;
            .line-height();
            left: 0;

            &:before {
                content: '\f096';
                font-family: icomoon;
                color: @header-color;
            }
        }

        a {
            text-decoration: underline;

            &:hover {
                text-decoration: none;
            }
        }
    }
}

.goshop-modal-header {
    background-color: @light-bg;
    height: 60px;
    color: @header-color;
    display: flex;
    align-items: center;
    font-size: 21px;
    border-bottom: 1px solid @border-color;

    &:before {
        content: '\e900';
        font-family: icomoon;
        font-weight: normal;
        width: 60px;
        height: 60px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 22px;
    }
}

.goshop-modal-body {
    padding: 20px;
    min-width: 100%;
}

.goshop-modal-close {
    color: @header-color;
    cursor: pointer;
    font-style: normal;
    height: 60px;
    position: absolute;
    right: 0;
    top: 0;
    width: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;

    &:after {
        content: '\e803';
        font-family: icomoon;
    }
}

// MODAL TYPES
.error-modal {

    .goshop-modal-header:before {
        color: @error-color;
    }
}

.success-modal {

    .goshop-modal-header:before {
        content: '\e801';
        color: @success-color;
        font-size: 30px;
    }
}

.form-modal .goshop-modal {
    width: 60vw;
}

.cart-modal {

    .popup-validation-error {
        color: @error-color;
        margin-bottom: 10px;
        width: 100%;
    }

    .cart-popup-row-wrapper {
        display: flex;

        > div > strong {
            color: @header-color;
            font-weight: normal;
        }
    }

    .goshop-modal-body section {
        padding-bottom: 20px;

        figure {
            width: @custom-width;
            height: @custom-height;
            flex-shrink: 0;
            margin-right: 15px;
            display: flex;
            justify-content: center;
            align-items: center;

            img {
                max-width: 100%;
                max-height: 100%;
            }
        }

        > div:not(.cart-popup-row-wrapper) {
            display: flex;
            flex-direction: column;
            justify-content: center;
        }

        ul {
            text-transform: lowercase;
            margin-top: 5px;

            span {

            }

            strong {
                color: @header-color;
                font-weight: normal;
            }
        }
    }

    footer {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }
}

.newsletter-cookie-modal {

    .goshop-modal {
        width: 900px;
    }

    .goshop-modal-body {
        display: flex;
        padding: 0;
    }

    .img-wrapper {
        width: 50%;
        flex-shrink: 0;
        aspect-ratio: 1;

        img {
            display: block;
            object-fit: cover;
            width: 100%;
            height: 100%;
        }
    }

    .text-wrapper {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 30px;
        gap: 10px;

        > img {
            width: auto;
            height: auto;
            max-width: 100%;
            max-height: 80px;
            align-self: flex-start;
        }
    }

    .field-wrapper {

        .form-hint {
            position: absolute;
            margin-top: -3px;
        }
    }

    .headline {
        margin-bottom: 0 !important;
    }

    .goshop-modal-body .newsletter-cookie-input {
        margin-top: 12px;
        margin-bottom: 12px;
    }

    label {
        margin-bottom: 15px;
    }
}

.captcha-wrapper {
    margin: 15px 0;
    clear: both;
}

.question-form-text {
    margin-bottom: 15px;
}

// PRIVACY BOX
body:has(.left-side-cookies) {

    .privacy-box-trigger {
        left: 20px;

        &:before {
            left: 25px;
            border-top-right-radius: 100vw;
            border-bottom-right-radius: 100vw;
            padding: 0 25px 0 40px;
            transform-origin: left center;
        }
    }

    .privacy-box {
        left: 20px;
    }
}

body:has(.right-side-cookies) {

    .privacy-box-trigger {
        right: 20px;

        &:before {
            right: 25px;
            border-top-left-radius: 100vw;
            border-bottom-left-radius: 100vw;
            padding: 0 40px 0 25px;
            transform-origin: right center;
        }
    }

    .privacy-box {
        right: 20px;
    }
}

.privacy-box-trigger {
    display: none;
    position: fixed;
    bottom: 20px;
    z-index: 999;
    opacity: 1;
    color: white;
    width: 50px;
    height: 50px;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    font-size: 25px;
    filter: drop-shadow(0 3px 8px rgba(0,0,0,.2));
    -webkit-tap-highlight-color: transparent;

    &:after {
        content: '';
        position: absolute;
        inset: 0;
        width: 100%;
        height: 100%;
        background-color: @sec-color;
        border-radius: 50%;
        z-index: 1;
    }

    img {
        filter: brightness(0) invert(1);
        position: relative;
        z-index: 2;
        max-height: 60%;
        width: auto;
        -webkit-tap-highlight-color: transparent;
    }

    &.show {
        display: flex;
    }

    &:before {
        content: 'Ustawienia ciasteczek';
        position: absolute;
        top: 0;
        height: 100%;
        background-color: @header-color;
        color: white;
        font-size: var(--fontSize);
        white-space: nowrap;
        display: flex;
        align-items: center;
        z-index: 0;
        opacity: 0;
        transform: scaleX(0);
        transition: all .3s ease-in-out;
    }

    @media (min-width: 1280px) {
        &:hover {

            &:before {
                transform: scaleX(1);
                opacity: 1;
            }
        }
    }
}

.privacy-box {
    position: fixed;
    bottom: 20px;
    z-index: 10000;
    background-color: white;
    box-shadow: 0 0 20px rgba(0,0,0,.25);
    max-width: min(800px, 50vw);
    border-radius: 10px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 15px;

    &.preferences-visible {

        .privacy-box__consents {
            display: flex;
        }
    }
}

.privacy-box__header {
    display: flex;
    align-items: center;
    gap: 20px;

    img {
        width: auto;
        max-height: 30px;
    }

    strong {
        color: @header-color;
        font-size: 18px;
    }
}

.privacy-box__wrapper {
    display: flex;
    flex-direction: column;
    gap: 10px;
    overflow-x: hidden;
    overflow-y: auto;
}

.privacy-box__description {
    color: @header-color;
}

.privacy-box__consents {
    flex-direction: column;
    gap: 10px;
    display: none;
}

.consent-detail {
    display: flex;
    flex-direction: column;
    gap: 10px;
    color: @header-color;
    border-radius: 4px;
    position: relative;
    border: 1px solid @border-color;

    &.toggleable {

        strong {
            cursor: pointer;

            &:hover {
                color: @main-color;
            }

            &:before {
                content: '\e800';
                font-family: icomoon;
                font-weight: normal;
            }
        }
    }

    &.show {

        strong:before {
            transform: scaleY(-1);
        }

        .consent-detail__description {
            display: block;
        }
    }

    strong {
        font-weight: 600;
        display: flex;
        align-items: center;
        gap: 10px;
        flex-grow: 1;
        user-select: none;
        width: 100%;
        color: @header-color;
        padding: 10px;
    }

    span {
        color: @success-color;
        flex-shrink: 0;
        padding: 10px;
        padding-left: 0;
    }
}

.consent-detail__header {
    display: flex;
    align-items: center;
}

.consent-detail__description {
    width: 100%;
    display: none;
    font-size: 13px;
    padding: 10px;
    margin-top: -10px;
}

.consent-detail__checker {
    height: 28px;
    position: relative;
    width: 50px;
    flex-shrink: 0;
    margin-right: 10px;

    &.mandatory {
        display: none;
    }

    input {
        display: none;

        &:checked + div {
            background-color: @success-color;

            &:before {
                transform: translateX(22px);
            }
        }
    }

    div {
        background-color: #ccc;
        bottom: 0;
        cursor: pointer;
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
        transition: .4s;
        border-radius: 17px;

        &:before {
            content: '';
            background-color: white;
            bottom: 4px;
            height: 20px;
            left: 4px;
            position: absolute;
            transition: .4s;
            width: 20px;
            border-radius: 50%;
        }
    }
}

.privacy-box__actions {
    display: flex;
    gap: 10px;

    button {
        height: 40px;
        flex: 1 1 0;


    }
}

.privacy-box__links {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;

    a {
        color: @sec-color;
        text-decoration: underline;

        &:hover {
            text-decoration: none;
        }
    }
}

// FACEBOOK SIDE
#facebook-box {
    position: fixed;
    width: 320px;
    top: 268px;
    right: -320px;
    z-index: 999;
    transition: transform .3s ease;

    &.show {
        transform: translateX(-320px);

        #in-facebook-box {
            box-shadow: 0 0 30px rgba(0,0,0,.3);
        }
    }

    #fb-open {
        display: block;
        position: absolute;
        left: -45px;
        top: 17px;
        width: 45px;
        cursor: pointer;
        transform-origin: right center;
        font-style: normal;
        background-color: white;
        border-radius: 8px;

        &:before {
            content: '\e806';
            font-family: icomoon;
            color: #4a6ea8;
            font-size: 45px;
            line-height: 45px;
            width: 45px;
            height: 45px;
            text-align: center;
            display: block;
            z-index: 1;
        }

        &:hover {
            left: -42px;
            transform: scale(1.1);
        }
    }

    img {
        max-width: 100%;
    }
}

#in-facebook-box {
    width: 320px;
    z-index: 999;
    cursor: default;
    background-color: #fff;
}

// SUGGESTIONS
#suggestions {
    top: ~"calc(100% + 10px)";
    left: 0;
    background-color: white;
    width: 720px;
    padding: 22px;
    .shadow();
    display: flex;
    flex-wrap: wrap;
    transform: translateY(-10px);

    .no-products-info {
        display: none;
        font-size: 18px;
        font-weight: bold;
        color: @header-color;
        width: 100%;
        margin-bottom: 10px;
    }

    [data-label] {
        margin-bottom: 10px;

        &:empty {
            display: none;
        }
    }

    &.loading {

        > *:not(.suggestions-loading) {
            display: none;
        }

        .suggestions-loading {
            display: flex;
        }

        .no-products-info {
            display: none !important;
        }
    }
}

.suggestions-loading {
    height: 200px;
    justify-content: center;
    align-items: center;
    width: 100%;
    display: none;

    span {
        border-radius: 50%;
        border: 4px solid @border-color;
        border-top-color: @header-color;
        animation: spin .5s infinite linear;
        transform: translateZ(0) rotate(0deg);
        transform-origin: center;
        -webkit-transform-style: preserve-3d;
        width: 100px;
        height: 100px;
    }
}

@media (min-width: 1280px) {
    .suggestion-column-wrapper {
        display: none;
        width: 100%;

        &:has([data-label]:not(:empty)) {
            display: flex;

            & + .suggestion-last-phrases {
                display: none;
            }
        }
    }
}

.suggestion-left-column {
    width: 33%;
    flex-shrink: 0;
    display: none;

    &:has(:not(:empty)) {
        display: block;
    }
}

.suggestion-result-categories,
.suggestion-result-producers,
.suggestion-result-products,
.suggestion-last-phrases {

    &:before {
        content: attr(data-label);
        display: block;
        color: @header-color;
        font-weight: bold;
        font-size: 18px;
    }
}

.suggestion-result-categories,
.suggestion-result-producers {
    padding-right: 30px;

    a {
        display: inline-block;
        padding: 2px 0;
        color: @font-color;

        &:hover {
            text-decoration: underline;
        }
    }
}

.suggestion-last-phrases {
    width: 100%;
    margin-bottom: 10px;

    span {
        display: flex;
        align-items: center;
        cursor: pointer;
        padding: 5px 0;

        &:before {
            content: '\e802';
            font-family: icomoon;
            color: @header-color;
            width: 35px;
            font-size: 18px;
            flex-shrink: 0;
        }

        &:hover {
            color: @header-color;
        }
    }

    li {
        display: flex;
        align-items: center;

        &.hidden {
            display: none;
        }
    }

    .delete-hint-suggestion {
        margin-left: auto;
        font-style: normal;
        color: @header-color;
        width: 20px;
        height: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 11px;
        cursor: pointer;

        &:after {
            content: '\e803';
            font-family: icomoon;
        }

        &:hover {
            color: @error-color;
        }
    }
}

.suggestion-result-categories {
    margin-bottom: 22px;
}

.suggestion-result-products {
    flex-grow: 1;
}

.suggestion-product {
    margin-top: 10px;

    &:hover {
        
        b {
            text-decoration: underline;
        }
    }

    a {
        display: block;
        position: relative;
        min-height: 60px;
        padding-left: 80px;
    }

    .size-custom {
        position: absolute;
        top: 0;
        left: 0;
        width: 60px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    img {
        max-width: 100%;
        max-height: 100%;
        align-self: center;
    }

    b {
        color: @header-color;
        display: block;

    }

    span {
        color: @font-color;
    }
}

a.suggestions-advanced-search {
    width: 100%;
    color: @header-color;
    text-decoration: underline;

    &:hover {
        text-decoration: none;
    }
}

// SHOWCASE
@keyframes pointAnimation {
    0% {
        transform: scale(1);
        opacity: 1;
    }

    50% {
        transform: scale(1.4w);
        opacity: .75;
    }

    100% {
        transform: scale(1);
        opacity: 1;
    }
}

[data-showcase] {
    clear: both;
    width: 100%;

    figure {
        position: relative;
        width: 100%;
    }

    img {
        display: block;
        margin: 0 auto;
        width: 100% !important;
    }

    em {
        display: block;
        text-align: center;
        font-style: normal;
        margin-top: 10px;
    }
}

.showcase-canvas {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.showcase-point {
    transition: border-color 250ms ease-in-out,opacity 250ms ease-in-out,visibility 250ms ease-in-out;
    transform: translateX(-1rem) translateY(-1rem) scale(0.999);
    padding: 0;
    width: 2rem;
    height: 2rem;
    border: 2px solid transparent;
    position: absolute;
    background: rgba(0,0,0,.2);
    border-radius: 64px;
    line-height: .5;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    &:hover {
        border-color: rgba(255,255,255,.5);
        background: rgba(0,0,0,.4);
    }

    &:before {
        content: '';
        position: absolute;
        top: -0.5rem;
        left: -0.5rem;
        right: -0.5rem;
        bottom: -0.5rem;
        display: block;
    }

    &:after {
        content: '';
        transition: transform 250ms ease-in-out;
        position: relative;
        display: block;
        background: #fff;
        box-shadow: 0 1px 4px rgba(0,0,0,.55);
        width: .75rem;
        height: .75rem;
        border-radius: 64px;
        animation: pointAnimation 2s ease-in-out infinite both;
    }
}

.stars-rating {
    float: left;
    width: 96px;

    &.average-rating {
        position: relative;
        cursor: pointer;

        > div {
            width: 100%;
            overflow: hidden;

            &:nth-child(1) {

                li:after {
                    content: '\e80f';
                }
            }

            &:nth-child(2) {
                position: absolute;
                top: 0;
                right: 0;
                height: 100%;

                ul {
                    right: 0;
                    position: absolute;
                    top: 0;
                }
            }
        }
    }

    ul {
        float: left;
        display: flex;
        justify-content: space-between;
        width: 96px;
    }

    li {
        color: @main-color;

        &:after {
            content: '\e810';
            font-family: icomoon;
            display: block;
        }

        &.rated {

            &:after {
                content: '\e80f';
            }
        }
    }
}

// IE GRID FALLBACK
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .products-list,
    .products-list-tabs {
        display: flex;
        flex-wrap: wrap;

        .product {
            min-width: 25%;
            max-width: 30%;
            flex: 1 1 0px;
        }
    }
}

#auto-redirect {
    flex-direction: column;

    em {
        font-style: normal;
    }
}
